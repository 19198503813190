<template>

      <v-container class="">
         <v-snackbar
          v-model="snackbar"
          timeout="2000"
          :color="color"
          top
          right
        >
        <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              icon
              v-bind="attrs"
              @click="snackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
    {{text}}
         </v-snackbar>
        <div class="">
        <v-row >
           <v-col  order-md="first">
             <v-card flat>
               <h2 class="mb-8">FAQs</h2>
                <h6 class="black--text">What products does Etousoft offer?</h6>
                <p class=" mb-8">Etousoft offers its flagship time-tracking software called the TimeinPRO, which helps businesses monitor and improve employees’ productivity. The software records the working hours, the website and applications used and visited, screenshot of the monitor at every time interval the manager sets, mouse activity, and whether the keyboard is being typed on.</p>
                <h6 class="black--text">Do I need to pay for the software even if I only track my employees?</h6>
                <p class="mb-8">No. The software is free for the owner of the business. </p>
                <h6 class="black--text">Does it still work when offline?</h6>
                <p class="mb-8">Yes, users can still run TimeinPRO even when they are offline. The data will be uploaded to their timesheet when connection is restored.</p>
                <p>To read more FAQs. <router-link to="faqs"> CLICK HERE </router-link> </p>
                
             </v-card>
           </v-col>
           <v-col cols="12" md="6" order="first" order-md="last">
             <v-card flat >
               <h2 class="">Email us</h2>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                 class=""
                 
                 @submit.prevent="sendMessage"
              >
                <v-text-field
                  v-model="form.name"
                  :rules="nameRules"
                  label="Name"
                  
                  required
                ></v-text-field>

                <v-text-field
                  v-model="form.email"
                  :rules="emailRules"
                  label="E-mail"
                  
                  required
                ></v-text-field>
                <v-text-field
                  v-model="form.subject"
                  :rules="subjectRules"
                  label="Subject"
                  
                  required
                ></v-text-field>
                <v-textarea
                v-model="form.message"
                label="Message"
                auto-grow
                 :rules="messageRules"
                
                rows="3"
                row-height="25"
              ></v-textarea>
            <div>
              <v-row align="center" dense >
                <v-col cols="12" md="6">
               
                     <VueRecaptcha  @verify="verifyRecaptcha" class="g-recaptcha"   :sitekey="captcha.key" :loadRecaptchaScript="true" v-if="captcha.key" >
                  </VueRecaptcha >
                 
                  
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn  block outlined text color="#0A009B" type="submit"> Send Message </v-btn>
                </v-col>
              </v-row>
               
                </div>
                
                </v-form>
             </v-card>
           </v-col>
        </v-row>
        </div>
      </v-container>

    <!--End of  How to use our products -->
 
</template>

<script>

import  VueRecaptcha  from 'vue-recaptcha'
// For more options see below

export default {
components: { VueRecaptcha  },
  created() {
    if (this.$user.SuccessLogin()) {
      this.$router.push({ name: "Dashboard" });
    }
    this.captcha.key = this.$config.captcha.key
    this.captcha.secret = this.$config.captcha.secret
  },
  data() {
    return {
      captcha : {
        key : null,
        secret : null,
      },
      snackbar:false,
      multiLine: true,
      text: `Thank you for messaging us! We will send you a response shortly`,
      color:"primary",
       valid: false,
    
      nameRules: [
        v => !!v || 'Please provide your name',
      ],
      subjectRules: [
        v => !!v || 'Please provide subject of query',
      ],
       messageRules: [
        v => !!v || 'Please provide your message',
      ],
      emailRules: [
        v => !!v || 'Please provide email address',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
         form:{
         captcha: null,
         name:null,
         email:null,
         subject:null,
         message:null,
       },
    }
  },
  methods: {
    verifyRecaptcha(response){
      this.form.captcha = response
    },
    sendMessage(){
        if(!this.$refs.form.validate()){
          this.snackbar = true
          this.text = "Please fill up all fields"
          this.color = "red"        
                    
          }
        else if(this.form.captcha <= 0){
          this.snackbar = true
          this.text = "Please answer captcha"
          this.color = "red"    
        }
        else{

        this.axios.post('supportemail',this.form)
                      .then(
                  
                        this.snackbar = true,
                        this.color="primary",
                        this.text = 'Thank you for messaging us! We will send you a response shortly',
                        this.$refs.form.reset()
                      )
                      .catch(({data}) =>{
                                this.text = data.error
                      } )
          }
    },
  },
};
</script>

<style scoped>
.btn{
  text-transform: none;
}
.g-recaptcha{
  transform:scale(0.77);
  -webkit-transform:scale(0.77);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
}
#rc-imageselect {
  transform:scale(0.77);
  -webkit-transform:scale(0.77);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
}
@media screen and (max-height: 575px){
#rc-imageselect, .g-recaptcha {transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;}
}
</style>
